import React from "react";

const Disclaimer: React.FC = () => {
  return (
    <div className="">
      <h2 className="mb-2 text-center">Disclaimer</h2>
      <p className="mx-24 pb-10">
        The Bitcoin Retirement Calculator provided on this website is for
        informational and educational purposes only. The results generated by
        the calculator are based on the inputs provided by the user and are not
        guaranteed to be accurate, complete, or up-to-date. The calculator is a
        tool designed to help users estimate potential outcomes based on various
        assumptions and should not be relied upon as financial, investment, or
        legal advice.
      </p>
      <div className="text-center">
        <span className="font-bold my-2">Important Considerations:</span>
      </div>
      <ul className="list-disc list-inside mx-24">
        <li>
          <span className="font-bold">Assumptions and Projections:</span> The
          calculations are based on user-provided inputs and assumptions,
          including but not limited to, initial Bitcoin balance, Bitcoin price
          growth rate, inflation rate, withdrawal rate, and other variables.
          These assumptions may not reflect real-world conditions and can
          significantly impact the results.
        </li>
        <li>
          <span className="font-bold">Market Volatility:</span> The value of
          Bitcoin and other cryptocurrencies can be highly volatile. Historical
          performance is not indicative of future results. The calculator does
          not account for potential market fluctuations, regulatory changes, or
          other unforeseen events that may affect the value of Bitcoin.
        </li>
        <li>
          <span className="font-bold">No Financial Advice:</span> This
          calculator is not intended to provide financial, investment, or legal
          advice. Users should consult with a qualified financial advisor or
          investment professional before making any decisions based on the
          results of this calculator.
        </li>
        <li>
          <span className="font-bold">Data Accuracy:</span> While efforts are
          made to ensure the accuracy of the data and assumptions used in the
          calculator, no guarantees are made regarding the accuracy,
          completeness, or reliability of the information provided.
        </li>
        <li>
          <span className="font-bold">User Responsibility:</span> Users are
          solely responsible for the decisions they make based on the results of
          this calculator. The creators of this website and the Bitcoin
          Retirement Calculator do not accept any liability for any loss or
          damage incurred by users as a result of using this tool.
        </li>
      </ul>
      <div className="text-center m-10">
        <p>
          By using the Bitcoin Retirement Calculator, you acknowledge and agree
          to the terms of this disclaimer.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
